exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-shenclinic-js": () => import("./../../../src/pages/shenclinic.js" /* webpackChunkName: "component---src-pages-shenclinic-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-tcherbs-js": () => import("./../../../src/pages/tcherbs.js" /* webpackChunkName: "component---src-pages-tcherbs-js" */),
  "component---src-pages-womenshealth-js": () => import("./../../../src/pages/womenshealth.js" /* webpackChunkName: "component---src-pages-womenshealth-js" */)
}

